<template>
    <div class="callLog">
        <el-descriptions :column="2" :labelStyle="{ wordBreak: 'keep-all' }">
            <el-descriptions-item label="通知ID">{{ info.callId }}</el-descriptions-item>
            <el-descriptions-item label="姓名">{{ info.callName }}</el-descriptions-item>
            <el-descriptions-item label="电话">{{ info.callPhone }}</el-descriptions-item>
            <el-descriptions-item label="通话状态">{{ info.statusCode }}</el-descriptions-item>
            <el-descriptions-item label="通话时长(秒)">{{ info.duration }}</el-descriptions-item>
            <el-descriptions-item label="接通时间">{{ info.startTime || '--' }}</el-descriptions-item>
            <el-descriptions-item label="结束时间" :span="2">{{ info.endTime }}</el-descriptions-item>
            <el-descriptions-item label="通知内容">{{ info.content }}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
import { selectRiskLogVoiceDetail } from '@/api/homeSave'
export default {
    // 通话记录
    name: 'CallLog',
    props: {
        currentItem: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            info: {}
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            selectRiskLogVoiceDetail({ callId: this.currentItem.callId }).then(res => {
                this.info = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.callLog {
    padding: 20px;
    ::v-deep .el-descriptions {
        font-size: 16px;
    }
    ::v-deep .el-descriptions__body {
        color: #333;
    }
}
</style>
