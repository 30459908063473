import request from '../utils/request'
import config from './config'

/**
 * 智慧居家安全
 */

// 安装管理列表
export function installPage(data) {
    return request({
        url: `${config.CIVIL_HOST}/equipmentOrder/web/selectPage`,
        method: 'post',
        data: data
    })
}

// 安装管理详情
export function getDetail(params) {
    return request({
        url: `${config.CIVIL_HOST}/equipmentOrder/web/orderDetails`,
        method: 'get',
        params
    })
}

//  安装管理导出
export function installPageDown(data) {
    return request({
        url: `${config.CIVIL_HOST}/equipmentOrder/web/pageDown`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 风险事件列表
export function selectRiskEventList(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/selectRiskEventList`,
        method: 'post',
        data: data
    })
}

// 查询风险事件处理进度
export function getRiskEventProgress(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/getRiskEventProgress`,
        method: 'get',
        params: params
    })
}

// 风险事件--日志详情
export function selectRiskLog(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/selectRiskLog`,
        method: 'get',
        params
    })
}

// 风险事件--日志详情--通话记录
export function selectRiskLogVoiceDetail(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/selectRiskLogVoiceDetail`,
        method: 'get',
        params
    })
}

// 风险事件--日志详情--处理反馈详情
export function selectRiskLogFeedBackDetail(params) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/selectRiskLogFeedBackDetail`,
        method: 'get',
        params
    })
}

// 设备列表
export function getDevicesList(params) {
    return request({
        url: `${config.CIVIL_HOST}/managerDevice/getGroupDeviceType`,
        method: 'get',
        params
    })
}

// 设备绑定列表
export function getDevicesBindingList(data) {
    return request({
        url: `${config.CIVIL_HOST}/managerDevice/bindDeviceList`,
        method: 'post',
        data
    })
}

// 下载绑定的设备信息
export function downBindExcel(data) {
    return request({
        url: `${config.CIVIL_HOST}/managerDevice/bindDeviceListDown`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// web风险事件分页记录下载
export function riskEventListPageDown(data) {
    return request({
        url: `${config.CIVIL_HOST}/pensionRiskEvent/riskEventListPageDown`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}
